const courses = [
  {
    course_id: 'learn-git',
    title: 'Learn git the right way',
    short_description: 'Git is an essential tool for code collaboration and version control in any language. Learn how to keep track of your code when working by yourself and how to share your code with others for review.',
    image_location: '/images/courses/version_control_git.jpeg',
    price: 0,
    contents: [
      'What is git?',
      'Installing git',
      'Why should I learn git from the command line?',
      'General command line basics',
      'What is a repository?',
      'What is a remote, such as GitHub?',
      'Creating a repository',
      'Understanding commits and branches',
      'Cloning',
      'Making commits and branches',
      'Getting around and orienting yourself',
      'Pushing and pulling',
      'Working towards collaboration',
      'The feature branch workflow',
      'Making a pull request',
      'Resetting',
      'Stashing',
      'Resolving merge conflicts',
      'The .gitignore file',
      'Cherry picking',
      'Secrets and git',
    ]
  }
]

export default courses
