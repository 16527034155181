<template>
  <div class="blogposts my-3">
    <h1 class="text-center">Blog</h1>
    <div class="mx-1 mt-3">
      <router-link to="/subscribe">
        <button type="button"
                class="btn btn-outline-light w-100 mb-3"
                data-bs-theme="dark">
          Subscribe
        </button>
      </router-link>
    </div>
    <div v-for="post in posts" :key="post.link">
      <router-link :to="'/blog/' + post.link">
        <div class="row blogpost mb-2 py-2 mx-1">
          <div class="col-10"><h3>{{post.name}}</h3></div>
          <div class="blogpost-date mr-auto">{{post.date}}</div>
          <div class="col-12"><p><i>{{post.description}}</i></p></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      posts: [
        {
          name: 'Unit testing and double-entry bookkeeping',
          description: 'Be like the Medicis.',
          link: 'unit-testing-and-double-entry-bookkeeping',
          date: '7 Feb. 2025'
        },
        {
          name: 'Chanto',
          description: 'What we can learn from the Japanese.',
          link: 'chanto-or-what-we-can-learn-from-the-japanese',
          date: '26 Jan. 2025'
        },
        {
          name: 'Hiring a developer for my band',
          description: 'Why software developers are closer to artists than to factory workers.',
          link: 'hiring-a-developer-for-my-band',
          date: '4 Dec. 2024'
        },
        {
          name: 'The three pillars of a bug report',
          description: 'A mental framework to structure bugfixing.',
          link: 'the-three-pillars-of-a-bug-report',
          date: '9 Nov. 2024'
        },
        {
          name: 'What is money and how does "money printing" work?',
          description: 'The most basic explanation of how money works, focused on the euro currency.',
          link: 'what-is-money-and-how-does-money-printing-work',
          date: '17 Sep. 2024'
        },
        {
          name: 'Lessons learnt building a real-time audio application in Python',
          description: 'Three lessons learnt while building Backlooper.app.',
          link: 'lessons-learnt-backlooper',
          date: '7 Sep. 2024'
        },
        {
          name: 'The big list of unelaborate statements',
          description: 'A list of unelaborate statements about building software.',
          link: 'the-big-list-of-unelaborate-statements',
          date: '17 Mar. 2024'
        },
        {
          name: 'When cheap energy turns green',
          description: 'Does cheap power mean green power?',
          link: 'when-cheap-energy-turns-green',
          date: '2 Dec. 2023'
        },
        {
          name: 'Robotaxis and AI-hardness',
          description: 'In AI, some problems are harder than others.',
          link: 'robotaxis-and-AI-hardness',
          date: '16 Jan. 2022'
        },
        {
          name: 'Adding confidence intervals to maximum likelihood estimates using sympy',
          description: 'Not pretty, but interesting.',
          link: 'mle-ci',
          date: '2 Jun. 2021'
        },
        {
          name: 'Thinking in orders of magnitude',
          description: 'Where physicists have an edge over computer scientists.',
          link: 'orders-of-magnitude',
          date: '23 Jan. 2021'
        },
        {
          name: 'How, why and the smelly washing machine',
          description: 'Why self-documenting code does not exist.',
          link: 'how-why-and-the-smelly-washing-machine',
          date: '2 Nov. 2020'
        },
        {
          name: 'Datetimes and DST',
          description: 'Not as simple as it looks, but not rocket science either.',
          link: 'datetimes-and-dst',
          date: '17 Oct. 2020'
        },
        {
          name: 'The number of bugs grows exponentially',
          description: 'How the number of bugs can grow exponentially and what can be done about it.',
          link: 'number-of-bugs-grows-exponentially',
          date: '8 Sep. 2020'
        },
        {
          name: 'Strings, bytes and unicode',
          description: 'There tends to be confusion about strings, bytes and unicode in Python 2 vs. 3. This article explains that it is not as confusing as it might seem.',
          link: 'strings-bytes-unicode',
          date: '22 Apr. 2020'
        },
        {
          name: 'Intro to git',
          description: 'Introduction to git, concluding with intermediate examples.',
          link: 'intro-to-git',
          date: '13 Mar. 2019'
        }
      ]
    }
  },
  created () {
    document.title = "Blog"
  }
}
</script>

<style scoped>
  .blogposts {
    text-align: left;
  }
  .blogpost {
    background-color: rgb(43, 43, 43);
    border-radius: 10px;
  }
  .blogpost:hover {
    background-color: rgb(30, 30, 30);
    cursor: pointer;
  }
  .blogpost-date {
    text-align: right;
    white-space: nowrap;
  }
  button {
    font-family: "Open Sans", sans-serif;
    background-color: rgb(43, 43, 43);
    border: none;
    font-size: 24px;
    color: #ddd;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
</style>