<template>
  <div class="projects my-3">
    <h1 class="text-center mb-3">Projects</h1>
    <div v-for="project in projects"
         :key="project">
      <a :href="project.link">
        <div class="row project my-2 py-2 mx-1">
          <div class="col col-10"><h3>{{project.name}}</h3></div>
          <div class="col col-12"><p><i>{{project.description}}</i></p></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        projects: [
          {
            name: 'nfoursid',
            description: 'Python implementation of N4SID, Kalman filtering and state-space models',
            link: 'https://github.com/spmvg/nfoursid',
          },
          {
            name: 'evt',
            description: 'Estimators and analysis for extreme value theory (EVT) in Python',
            link: 'https://github.com/spmvg/evt',
          },
          {
            name: 'csvkanban.com',
            description: 'Turn CSV files into a simple kanban board',
            link: 'https://www.csvkanban.com/',
          },
          {
            name: 'backlooper.app',
            description: 'Loop audio without having to trigger beforehand',
            link: 'https://www.backlooper.app/',
          },
          {
            name: 'asdfuzz',
            description: 'Web application fuzzer that automatically detects and fuzzes data in HTTP requests',
            link: 'https://github.com/spmvg/asdfuzz',
          },
          {
            name: 'txttree',
            description: 'Command line tool for organizing and displaying hierarchical relationships using a folder-based structure and text files',
            link: 'https://github.com/spmvg/txttree',
          },
        ]
      }
    },
    created () {
      document.title = "Projects"
    }
  }
</script>

<style scoped>
  .projects {
    text-align: left;
  }
  .project {
    background-color: rgb(43, 43, 43);
    border-radius: 10px;
  }
  .project:hover {
    background-color: rgb(30, 30, 30);
    cursor: pointer;
  }
  .project-date {
    text-align: right;
    white-space: nowrap;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
</style>