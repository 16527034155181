import { Authenticator } from "@aws-amplify/ui-vue"
import "@aws-amplify/ui-vue/styles.css"

import { Amplify } from 'aws-amplify'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '1nhnfci3vs6j2i7jhekisg9c7t',
      userPoolId: 'eu-central-1_a68mXT8gf',
      loginWith: {
        username: false,
        email: true,
      }
    }
  }
})

export default Authenticator
