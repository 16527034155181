<template>
  <Authenticator class="m-5">
    <div class="row">
      <div class="md col-12 mx-auto my-2">
        <div class="d-flex justify-content-center align-items-center"
             v-if="page_contents.length == 0">
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="row mt-3 mx-1"
             v-if="page_contents.length != 0">
          <div class="col-12">
            <div class="progress"
                 data-bs-theme="dark">
              <div class="progress-bar progress-bar-striped"
                   role="progressbar"
                   :style="{ width: progress + '%' }"
                   :aria-valuenow="progress"
                   aria-valuemin="0"
                   aria-valuemax="100">
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-1 mt-3">
          <div class="col-12"
               v-for="content in page_contents"
               :key="content">
            <span v-if="content.type=='html'"
                  v-html="content.html">
            </span>
            <div v-if="content.type=='alert'"
                 class="alert alert-light"
                 role="alert"
                 data-bs-theme="dark">
              {{ content.message }}
            </div>
            <div v-if="content.type=='danger'"
                 class="alert alert-danger"
                 role="alert"
                 data-bs-theme="dark">
              {{ content.message }}
            </div>
            <div v-if="content.type=='warning'"
                 class="alert alert-warning"
                 role="alert"
                 data-bs-theme="dark">
              {{ content.message }}
            </div>
          </div>
        </div>
        <div class="row mx-1 mt-4">
          <div v-if="question"
               class="question pt-3 px-3 pb-1 mb-2">
            <h5>{{question.question}}</h5>
            <form class="mb-3"
                  @submit.prevent="submit_post_question">
              <textarea class="form-control w-100"
                        data-bs-theme="dark"
                        name="student_response"
                        id="student_response_textarea"
                        required
                        rows="2"
                        maxlength="750" />
              <div class="d-flex flex-column my-1">
                <button type="submit"
                        class="btn btn-primary align-self-end"
                        :disabled="question.submit_disabled || question.spoil_answer || question.answer"
                        data-bs-theme="dark">
                  Proceed
                </button>
              </div>
            </form>
            <div v-if="question.error">
              <h6>Error</h6>
              <div class="alert alert-danger"
                   role="alert"
                   data-bs-theme="dark">
                {{ question.error }}
              </div>
            </div>
            <div v-if="question.hint">
              <h6>Hint</h6>
              <div class="alert alert-warning"
                   role="alert"
                   data-bs-theme="dark">
                {{ question.hint }}
              </div>
            </div>
            <div v-if="question.answer">
              <h6>Answer</h6>
              <div class="alert alert-success"
                   role="alert"
                   data-bs-theme="dark">
                <span v-html="question.answer"></span>
              </div>
            </div>
            <div v-if="question.spoil_answer">
              <h6>Answer</h6>
              <div class="alert alert-light"
                   role="alert"
                   data-bs-theme="dark">
                <span v-html="question.possible_answers"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2"
             v-if="page_contents.length != 0">
          <div class="col-12">
            <div class="row">
              <div class="col-3">
                <button type="button"
                        class="btn btn-outline-light float-start m-2 arrow-button"
                        @click="go_previous(page_id)"
                        :disabled="page_id == 0 ? true : false">
                  ←
                </button>
              </div>
              <div class="col-6">
                <span>&nbsp;</span>
              </div>
              <div class="col-3"
                   v-if="!proceeding_is_disabled">
                <button type="button"
                        class="btn btn-outline-light float-end m-2 arrow-button"
                        @click="go_continue(page_id)">
                  →
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Authenticator>
</template>

<script>
  import { Hub } from 'aws-amplify/utils'
  import MarkdownIt from 'markdown-it'
  import Authenticator from '@/authenticator/authenticator.js'
  import { get_course, post_question } from '@/courses/backend.js'

  export default {
    components: {
      Authenticator,
    },
    data() {
      return {
        page_contents: [],
        max_page: null,
        question: null,
        question_progress: null,
      }
    },
    methods: {
      go_continue(current_page) {
        this.$router.push('/course/' + this.course_id + '/page/' + (current_page + 1))
        this.$forceUpdate()
      },
      go_previous(current_page) {
        this.$router.push('/course/' + this.course_id + '/page/' + (current_page - 1))
        this.$forceUpdate()
      },
      clear_student_response() {
        var textarea = document.getElementById("student_response_textarea")
        if (!textarea) {return}
        textarea.value = ""
      },
      set_page_contents() {
        console.debug('Requesting course info')
        get_course(this.course_id, this.page_id)
          .then(course => {
            if (!course) {
              console.debug('There are no course contents')
              return
            }

            if (course['redirect'] != null) {
              this.$router.push('/course/' + this.course_id + '/page/' + course['redirect'])
              this.$forceUpdate()
              return
            }

            this.max_page = course['max_page']
            this.question = course['question']
            this.question_progress = course['progress']

            console.debug('Rendering text')
            this.page_contents = []
            course['page_parts'].forEach(page_part => {
              if (page_part.type == "md") {
                var md = new MarkdownIt()
                var rendered_text = md.render(page_part['content'])
                this.page_contents.push({
                  type: "html",
                  html: rendered_text,
                })
              } else if (["alert", "danger", "warning"].includes(page_part.type)) {
                this.page_contents.push(page_part)
              }
            })
            this.clear_student_response()
          })
      },
      submit_post_question(submit_event) {
        var data = new FormData(submit_event.target)
        var data_object = {}
        data.forEach((value, key) => {
          data_object[key] = value
        })
        this.question.submit_disabled = true
        post_question(data_object, this.course_id, this.page_id).then(response => {
          this.question.answer = response.answer
          this.question.hint = response.hint
          this.question.error = response.error
          this.question.submit_disabled = false
        })
      }
    },
    computed: {
      course_id() {
        var course_id = this.$route.params.course_id
        const course_regex = /^[a-z0-9-]+$/
        const matches = course_regex.exec(course_id)
        if (!matches) {
          // eslint-disable-next-line
          this.$router.push('/404')
          this.$forceUpdate()
          return null
        }
        return course_id
      },
      page_id() {
        var page_id = this.$route.params.page_id
        const page_id_regex = /^[0-9]+$/
        const matches = page_id_regex.exec(page_id)
        if (!matches) {
          // eslint-disable-next-line
          this.$router.push('/404')
          this.$forceUpdate()
          return null
        }
        return parseInt(page_id)
      },
      progress() {
        return (this.page_id / this.max_page) * 100
      },
      proceeding_is_disabled() {
        if (
          (this.page_id == this.max_page)
          || (this.question && (this.page_id > this.question_progress) && !this.question.answer)
        ) {
          return true
        }
        return false
      }
    },
    mounted: function () {
      this.set_page_contents()

      Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
          case 'signedIn':
            console.debug('User has logged in. Reloading page contents.')
            this.set_page_contents()
        }
      })
    },
    watch: {
      course_id: function () {
        console.debug('Watched course_id')
        this.set_page_contents()
      },
      page_id: function () {
        console.debug('Watched page_id')
        this.set_page_contents()
      }
    }
  }
</script>

<style scoped>
  .md {
    text-align: left;
    background-color: rgb(43, 43, 43);
    border-radius: 10px;
  }

  .question {
    background-color: rgb(60, 63, 65);
    border-radius: 10px;
  }

  .arrow-button {
    font-size: 15px;
  }

  span >>> img {
    margin: auto;
    display: block;
    max-width: 100%;
  }
  span >>> p {
    font-family: "Roboto", sans-serif;
  }
  span >>> li {
    font-family: "Roboto", sans-serif;
  }
  span >>> em {
    font-family: "Roboto", sans-serif;
  }
  span >>> h1 {
    font-family: "Open Sans", sans-serif;
  }
  span >>> h2 {
    font-family: "Open Sans", sans-serif;
  }
  span >>> h3 {
    font-family: "Open Sans", sans-serif;
  }
  span >>> h4 {
    font-family: "Open Sans", sans-serif;
  }
  span >>> h5 {
    font-family: "Open Sans", sans-serif;
  }
  span >>> h6 {
    font-family: "Open Sans", sans-serif;
  }
  span >>> code {
    color: #fff;
    background-color: rgb(56, 58, 60);
  }
  span >>> pre {
    background-color: rgb(56, 58, 60);
  }
  span >>> th {
    font-family: "Open Sans", sans-serif;
    padding: 5px;
  }
  span >>> td {
    color: #fff;
    padding: 5px;
  }
  span >>> table {
    background-color: rgb(56, 58, 60);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>