import { fetchAuthSession } from 'aws-amplify/auth';

const root_url = 'https://th4nl4s4m2.execute-api.eu-central-1.amazonaws.com/prod'

export function get_course(course_id, page_id) {
  return new Promise((resolve) => {
    fetchAuthSession().then(session => {
      if (!session.tokens) {
        console.debug('Missing session tokens')
        resolve(null)
        return
      }
      let token = session.tokens.idToken
      const headers = {
        "Authorization": "Bearer " + token
      }
      console.debug('Fetching GET course')
      fetch(root_url + "/course/" + course_id + "/page/" + page_id, { headers })
        .then(response => {
          if (response.status != 200) {
            return null
          }
          return response.json()
        })
        .then(data => {
          resolve(data)
        })
    })
  })
}

export function post_question(formdata, course_id, page_id) {
  return new Promise((resolve) => {
    fetchAuthSession().then(session => {
      if (!session.tokens) {
        console.debug('Missing session tokens')
        resolve(null)
        return
      }
      let token = session.tokens.idToken
      const headers = {
        "Authorization": "Bearer " + token
      }
      console.debug('Fetching POST question')
      fetch(
        root_url + "/question/course/"+ course_id + "/page/" + page_id, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formdata)
        }
      )
        .then(response => {
          if (response.status != 200) {
            return null
          }
          return response.json()
        })
        .then(data => {
          resolve(data)
        })
    })
  })
}
